html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 0;
}

button{
    border-radius: 50px;
    font-size: 30;
    font-family:'Roboto';
    padding:20;
    border-color: grey;
    cursor: pointer;
    position: relative;
}

body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
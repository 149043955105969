#content {
    width: 80%;
    height: 100%;
}
#chat {
    position: fixed;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
#chat #chat-container {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
}
#chat #chat-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
#chat #chat-form {
    position: relative;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
}
#chat #chat-form form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin-block-end: 0;
}
#chat #chat-form .separator {
    margin: 0;
    position: relative;
    display: block;
    height: 10px;
}
#chat #chat-form .separator:after {
    box-shadow: 0 -3px 2px -2px #e8eaed;
    content: "";
    height: 100%;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: opacity .3s ease;
    width: 100%;
}
#chat #chat-form input[type=text] {
    flex-grow: 1;
    margin-right: 10px;
    border-radius: 20px;
    border-color: #c7c7c7;
    border-width: 1px;
    font-size: 14px;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 20px;
    padding: 12px 16px;
    outline: none;
    box-shadow: none;
    border-style: solid;
}
#chat #chat-form button[type=submit] {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    color: rgb(11, 87, 208);
    fill: rgb(11, 87, 208);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
#chat #chat-form button[type=submit]:hover {
    background-color: #efefef;
}


#chat .row {
    width: 85%;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

#chat .row .headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding-left: 12px;
    padding-bottom: 1px;
    box-sizing: border-box;
}
#chat .row .headline .name {
    font-weight: 500;
    font-family: "Google Sans Text", "Google Sans", Roboto, Arial, sans-serif;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 16px;
    color: rgb(31, 31, 31);
    margin-right: 8px;
}
#chat .row .headline .date {
    font-weight: 500;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 11px;
    letter-spacing: 0.1px;
    line-height: 16px;
    color: rgb(94, 94, 94);
    margin-right: 8px;
}

#chat .row .message {
    background-color: rgb(242, 242, 242);
    border-radius: 15px;
    padding: 8px 12px;
    width: fit-content;
    color: rgb(31, 31, 31);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
    margin-bottom: 2px;
    box-sizing: border-box;
}
#chat .row.me {
    align-self: flex-end;
}
#chat .row.me .message {
    background-color: rgb(236, 243, 254);
    align-self: flex-end;
}
#good {
    width: max-content; 
    background:greenyellow; 
    position: inherit; 
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 40;
    font-family: 'Roboto';
    padding: 0 30 0 30;
    border-radius: 50px;
    z-index: 2000;
    flex-direction: column;
    padding-bottom: 20px;
    
}

#introChoix {
    position: inherit;
    width: auto; 
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    z-index: 1000;
    display: flex;
}

.container {
    position: fixed;
    align-items: center;
    justify-content: center; 
    display: flex;
}

#introChoix h2 {
    text-shadow: 1px 1px 2px #fff;
    font-family:'Roboto';
    font-size: 40
}
#introChoix div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
#introChoix button {
    margin-left: 5px;
    margin-right: 5px;
}
#introChoix button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}